import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby';
import { Grid, Row, Column, Text , Button} from '@dls/web';
import styled from 'styled-components';
import QueryString from 'query-string';
import ColorLogoMobile from '@dls/assets/images/logo_singtel@2x.png';
import media from '../helpers/media';
import Footer from '../components/Footer';
import { loginBannerErrorState } from '../views/LoginView/utils';


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
`;
const HeaderWrapper = styled.div`
  border-top: solid 4px #ED1A3D;
  box-shadow: rgba(0,0,0,.2) 0px 0px 7px 0px;

  div:first-child {
    max-width: 1200px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const BodyWrapper = styled(Grid)`
  max-width: 992px;
  ${media.sm`
    width: 100%;
  `}
`;

const LogoWrapper = styled(Column)`
  text-align: center;
  ${media.sm`
    text-align: left;
    padding: 0 0 0 20px;
  `}
`;

const LogoImage = styled.img`
  max-width: 120px;
  max-height: 120px;
`;

export default function TempLock() {
  const [msg, setMsg] = useState<string>('We’ve received too many login attempts');
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  
  function handleBack() {
    setLoginBannerError('Your account is temporarily locked for security reasons. Try again in 30 minutes.');
    navigate(`/login${window.location.search}`);
  }

  useEffect(() => {
    const qs = QueryString.parse(location.search);
    if(qs.maxlimit){
      setMsg('You have reached the limit for OTP requests');
    }
  }, []);
  
  return (
    <PageWrapper>
      <HeaderWrapper>
        <Grid>
          <Row>
            <LogoWrapper xs={12} sm={4} md={4} style={{padding:'0px'}}>
              <LogoImage src={ColorLogoMobile} alt="Logo" />
            </LogoWrapper>
          </Row>
        </Grid>
      </HeaderWrapper>
      <BodyWrapper>
          <Row>
            <Column xs={12} style={{textAlign: 'center'}}>
              <Text type="pageTitle" style={{padding:'80px 0 6px 0'}}>{msg}</Text>
              <Text style={{padding:'0 0 56px 0'}}>For security reasons, your account is temporarily locked. You may try to log in again in 30 minutes.</Text>
            </Column>
            <Column xs={12} sm={4} smOffset={4} style={{textAlign: 'center'}}>
              <Button
                text="Back to Home"
                secondary
                fullWidth
                onClick={handleBack}
              />
            </Column>
          </Row>
        </BodyWrapper>
      <Footer />
    </PageWrapper>
  );
}
